/*eslint-disable*/
const ko = {
  languageId: "Korean",
  languageName: "한국어",
  global: {
    defaultBackLinkText: "이전으로 돌아가기",
    yes: "예",
    no: "아니요",
    menu: {
      label: "메뉴",
      accountInfo: "계정 정보",
      biography: "@:screens.PeerMentorBiography.pageTitle",
      agreements: "계약",
      signOut: "로그아웃"
    },
    buttons: {
      goBack: "돌아가기",
      update: "업데이트",
      returnHome: "홈으로 돌아가기",
      save: "저장",
      editNotes: "메모 편집",
      saveUpdate: "저장/업데이트",
      ok: "확인",
      share: "공유",
      close: "닫기",
      goToLogin: "로그인 화면으로 이동",
      submit: "제출",
      proceed: "계속",
      sendProceed: "전송 후 계속",
      goHome: "홈 화면으로 이동",
      skip: "건너뛰기",
      updatePassword: "암호 업데이트",
      stillHere: "아직 여기 있어요",
      logOut: "로그아웃",
      sendVerification: "확인 보내기",
      beginApplication: "응용 프로그램 시작",
      cancel: "취소",
      upload: "업로드",
      delete: "삭제",
      acknowledge: "인정하다",
      shareStory: "당신의 이야기를 공유하세요"
    },
    errorMessages: {
      errorUsedPassword:
        "이전에 사용한 적이 있는 암호입니다. 가장 최근 5개 암호 중 어느 것도 재사용할 수 없습니다.",
      incorrectCurrentPassword: "잘못된 현재 암호",
      passwordExpirationWarning: "암호가 {num}일 후에 만료됩니다.",
      passwordExpirationLinkText: "암호 업데이트",
      emailTaken: "이 이메일 주소를 가진 계정이 이미 있습니다."
    },
    passwordExpired: {
      header: "암호 만료됨",
      message:
        "귀하와 귀하의 정보를 안전하게 보호하려면 {num}일마다 암호를 업데이트해야 합니다. 지금 업데이트해도 좋습니다."
    },
    sessionTimeoutWarning: {
      header: "시간 초과 경고",
      message: "비활성 상태로 인해 {Time} 후 로그아웃됩니다.",
      stillHere: "아직 여기 계시다면 알려주세요."
    }
  },
  fieldLabels: {
    datepickerPlaceholder: "선택하려면 클릭",
    select: "선택",
    languagePref: "기본 설정 언어:",
    email: "이메일",
    emailAddress: "이메일 주소",
    currentPassword: "현재 비밀번호",
    password: "암호",
    newPassword: "새 암호",
    passwordRequirementsHeader: "암호 요구 사항:",
    passwordRequirements: {
      uppercase: "하나 이상의 대문자",
      lowercase: "하나 이상의 소문자",
      symbol: "하나 이상의 기호",
      numbers: "하나 이상의 숫자",
      length: "12~60자",
      spaces: "공백 제외"
    },
    passwordRecencyRequirement:
      "가장 최근의 5개 암호 중 어느 것도 사용할 수 없습니다.",
    passwordShowAria: "암호 표시",
    passwordHideAria: "암호 숨기기",
    resetPassword: "새 암호 만들기",
    fieldShow: "표시",
    fieldHide: "숨기기",
    name: "성함",
    firstName: "이름",
    lastName: "성",
    phone: "전화번호",
    mobilePhone: "휴대전화 번호",
    city: "도시",
    state: "주",
    age: "나이",
    gender: "성별",
    language: "@:screens.PeerMentorAccountInfoLanguage.pageTitle",
    sessionNotes: "세션 메모",
    sessionNotePeer: "이 메모는 어떤 {peer}를 위한 것입니까?",
    sessionNoteReason: "만난 이유는 무엇입니까?",
    sessionNoteNotes: "{peer} 미팅의 메모 추가",
    sessionNoteNotesView: "미팅 메모",
    sessionNoteDurationDate: "세션 기간 및 날짜",
    sessionNoteDuration: "미팅 시간",
    sessionNoteDateFieldset: "날짜",
    sessionNoteDate: "미팅 날짜",
    sessionNoteDurationMinutes: "{num}분",
    sessionNoteUrgent: "멘티는 케어팀의 후속 조치가 필요합니다.",
    sessionNoteLeaving: "다른 공급자로 떠날 위험이 있는 {peer}.",
    sessionNotePurpose: "미팅 목적",
    sendMessage: "메시지를 입력하세요({users}와의 채팅은 기밀입니다)",
    sendMessageCoord: "메시지 입력",
    notes: "메모",
    day: "일",
    date: "날짜",
    whichPeer: "어떤 {peer}와 공유하시겠습니까?",
    selectPeer: "{peer} 선택",
    numPeers: "{num}명의 {peers} 선택됨",
    selectAll: "모두 선택",
    addShareNote: "공유하는 이유에 대한 메모 추가:",
    enterEmail: "이메일 주소 입력",
    surveyHate: "싫어요",
    surveyLove: "좋아요",
    surveyAwful: "끔찍해요",
    surveyFantastic: "멋져요",
    surveyCommentsFeel: "왜 그렇게 느끼는지에 대한 의견을 주세요.",
    surveyCommentsRating: "이 등급을 부여하는 이유에 대한 의견을 주세요.",
    notificationEmail: "이메일 알림",
    notificationSms: "SMS 알림",
    adminNotes: "관리자 메모",
    emailZoom: "Zoom 초대 이메일"
  },
  screens: {
    Login: {
      greeting: "환영합니다! 여기 계셔서 기쁘네요.",
      forgotPassword: "암호를 잊으셨나요?",
      btnLogIn: "로그인",
      errorBadCreds: "알 수 없는 암호 또는 이메일 주소",
      errorLockedAccount: "너무 많은 로그인 시도 실패로 인해 계정이 잠겼습니다. 자세한 내용은 이메일을 확인하십시오.",
      errorPasswordExpired: "비밀번호가 만료되었습니다. 아래의 비밀번호 찾기 링크를 사용하여 비밀번호를 재설정하십시오.",
      noAccount: "계정이 없나요?",
      signUp: "가입하기"
    },
    ResendInvitation: {
      enterEmail: "등록 초대를 받으려면 이메일 주소를 입력하세요.",
      enroll: "프로그램에 등록",
      emailConfirmation1: "귀하의 이메일이 시스템에 있는 경우 등록 링크가 포함된 이메일을 받게 됩니다.",
      emailConfirmation2: "이메일에 있는 링크를 클릭하고 비밀번호를 만든 다음 새 비밀번호를 사용하여 로그인하세요."
    },
    Apply: {
      pageTitle: "적용하다",
      menteeHeader: "멘토링을 받기 위해 신청하기",
      mentorHeader: "멘토가 되기 위해 지원하십시오",
      mentee: {
        emailLabel: "멘토링을 신청하기 전에 먼저 이메일 주소를 확인해 보겠습니다."
      },
      mentor: {
        emailLabel: "멘토가 되기 위해 신청하기 전에, 먼저 이메일 주소를 확인해보자."
      },
      emailConfirmation1: "받은 편지함으로 이메일 확인 링크를 보냈습니다. 즉시 받아야합니다.",
      emailConfirmation2: "이메일을 찾아 \"이메일 주소 확인\"버튼을 클릭하여 확인 절차를 완료하십시오.",
      emailConfirmationTroubleHeader: "문제가?",
      emailConfirmationTrouble1: "받은 편지함에 이메일이 표시되지 않으면 스팸 폴더를 확인하십시오.",
      emailConfirmationTrouble2: "확인 이메일을 아직 받지 못한 경우 다음 옵션 중 하나를 선택할 수 있습니다.",
      emailConfirmationTroubleReenter: "내 이메일 주소를 다시 입력하고 새 확인 보내기",
      emailSubmittedHeader: "성공! 이메일 주소 확인",
      emailSubmitted: "이메일 주소를 확인해 주셔서 감사합니다. 아래 버튼을 클릭하여 멘토링 신청을 시작하십시오.",
    },
    Interstitial: {
      termsHeader: "이용 약관",
      termsAgree: "예, 다음 내용을 읽었으며 이에 동의합니다phoneCall:",
      joinAgreement: "가입 동의",
      volunteerAgreement: "자원봉사 협약",
      privacy: "개인 정보 정책",
      license: "최종 사용자 라이선스 계약",
      btnSubmit: "내 계정 만들기"
    },
    PeerMentorHome: {
      pageTitle: "홈",
      coordinatorMessaging: "{coordinators}와 채팅",
      mentorMenteesLabel: "담당{MentorORPeerORPeers}",
      noMatchMessage: "{MentorORPeer} 매치를 기다리는 중",
      mentorQuestionsShort: "멘토 회의 준비",
      mentorQuestionsLong: "@:screens.PeerMentorHome.mentorQuestionsShort",
      addSessionNotes: "세션 메모 추가",
      resourcesLabel: "@:screens.ResourcesList.resourcesLabel",
      healthLibrary: "@:screens.ResourcesList.healthLibrary",
      communityResources: "@:screens.ResourcesList.communityResources",
      surveyLink: "여러분의 피드백으로 변화를 만들어 보세요",
      surveyAcknowledgement: "의견을 보내 주셔서 감사합니다!",
      menteeDisclaimer: "면책 조항: 의학적 조언을 동료 멘토에게 의존하지 마십시오. 귀하의 진료에 관한 구체적인 질문은 담당 의사에게 문의하십시오. 응급 상황인 경우 911에 전화하거나 가까운 응급실로 가십시오.",
      mentorDisclaimer: "알림: 동료 멘티에게 의학적 조언을 제공하지 마십시오. 의료에 관한 구체적인 질문은 담당 의사에게 문의하도록 하십시오. 응급 상황인 경우 911에 전화를 걸거나 가장 가까운 응급실로 가도록 요청하십시오.",
      notYetEnrolled: "아직 등록되지 않았습니다",
      videoStories: "환자 비디오 스토리",
      viewAllVideos: "모든 비디오 스토리 보기",
      viewFewerVideos: "비디오 스토리 간략히 보기",
      careGuides: "관리 가이드",
      takingBreak: "휴식을 취하시나요? {Coordinator} 연락하세요"
    },
    PeerMeetingPrep: {
      pageTitle: "회의 준비",  
      headerTitle: "{Mentor} 회의 준비",
      concernsHeader: "귀하의 우려 사항",
      orderDescription: "(가장 중요한 순서대로)",
      questionsHeader: "귀하의 질문과 우선순위",
      notesLabel: "추가 참고 사항",
      changesSaved: "변경사항이 저장됨",
      linkTitle: "멘토 회의 준비"
    },
    DoctorMeetingPrep: {
      headerTitle: "의사 회의 준비",
      prioritiesHeader: "방문 시 우선순위"
    },
    PeerMentorVideos: {
      pageTitle: "비디오스토리",
    },
    ResourcesList: {
      pageTitle: "건강 라이브러리",
      pageTitleCommunity: "커뮤니티 리소스",
      resourcesLabel: "리소스",
      healthLibrary: "건강 자원",
      tabAllArticles: "모든 아티클",
      tabShared: "나와 공유",
      communityResources: "커뮤니티 리소스",
      noSharedArticles: "아직 공유된 아티클이 없습니다. 둘러보기",
      allArticlesLinkText: "모든 아티클",
      shareHeader: "{MentorORPeers}와(과) 이 아티클 공유",
      modalConfirmationHeader: "이 아티클을 공유했습니다",
      viewArticle: "기사 보기"
    },
    Tips: {
      pageTitleMentor: "멘토링 팁",
      pageTitlePeer: "건강 관리",
      mentorTipsHeader: "유용한 멘토링 팁",
      menteeTipsHeader: "유용한 팁들",
      viewAllTips: "모든 팁 보기",
      tipCounter: "유형 {num}",
      previousTip: "이전 유형",
      nextTip: "다음 유형",
    },
    PeerMentorAccountInfo: {
      pageTitle: "계정 정보",
      backLinkText: "메인 화면으로 돌아가기",
      accountInfoHeader: "계정 정보",
      managePersonalLinkText:
        "@:screens.PeerMentorAccountInfoPersonal.pageTitle",
      passwordHeader: "암호",
      managePasswordLinkText:
        "@:screens.PeerMentorAccountInfoPassword.pageTitle",
      notificationsHeader: "알림",
      manageNotificationsLinkText:
        "@:screens.PeerMentorAccountInfoNotifications.pageTitle",
      languageHeader: "@:screens.PeerMentorAccountInfoLanguage.pageTitle",
      manageLanguageLinkText: "언어 변경",
      notificationsOn:
        "@:screens.PeerMentorAccountInfoNotifications.notificationsOn",
      notificationsOff:
        "@:screens.PeerMentorAccountInfoNotifications.notificationsOff",
      yourPhoto: "당신의 사진",
      addPhoto: "사진 추가",
      addPhoto: "유효성",
      availability: "유효성",
      available: "현재 사용 가능",
      unavailable: "현재는 이용할 수 없습니다"
    },
    PeerMentorAccountInfoPersonal: {
      pageTitle: "개인 정보 업데이트",
      backLinkText: "계정 정보로 돌아가기",
      pageHeader: "@:screens.PeerMentorAccountInfoPersonal.pageTitle",
      modalConfirmationHeader: "프로필 업데이트됨"
    },
    PeerMentorAccountInfoPassword: {
      pageTitle: "암호 관리",
      backLinkText: "@:screens.PeerMentorAccountInfoPersonal.backLinkText",
      pageHeader: "@:screens.PeerMentorAccountInfoPassword.pageTitle",
      modalConfirmationHeader: "암호 업데이트됨"
    },
    PeerMentorAccountInfoNotifications: {
      pageTitle: "알림 관리",
      backLinkText: "@:screens.PeerMentorAccountInfoPersonal.backLinkText",
      pageHeader: "@:screens.PeerMentorAccountInfoNotifications.pageTitle",
      modalConfirmationHeader: "환경 설정 업데이트됨",
      notificationsOn: "켜기",
      notificationsOff: "끄기"
    },
    PeerMentorAccountInfoLanguage: {
      pageTitle: "언어 기본 설정",
      backLinkText: "@:screens.PeerMentorAccountInfoPersonal.backLinkText",
      pageHeader: "@:screens.PeerMentorAccountInfoLanguage.pageTitle",
      modalConfirmationHeader: "환경 설정 업데이트됨"
    },
    PeerMentorBiography: {
      pageTitle: "전기",
      editLink: "내 전기 편집",
      concerns: "내 관심사",
      concernsList: "내 고민 목록",
      happy: "나를 행복하게 만드는 것",
      priorities: "나의 우선순위와 목표",
      barriers: "건강을 유지하는 가장 큰 장벽",
      about_me: "나에 대해서",
      mentorSince: "나는 그때부터 {mentor)였다",
      journey: "나의 여행, 나의 경험",
      why_mentor: "나는 왜 멘토링을 좋아하는가?",
      view: "전기 보기",
      backToDetails: "세부 정보로 돌아가기",
      concernsTab: "우려사항",
      concernsHeader: "진단과 관련하여 어떤 우려 사항이 있습니까?",
      checkAll: "해당되는 모든 것을 체크하세요",
      worries: "가장 큰 압박감/걱정"
    },
    SessionNotesAdd: {
      pageTitle: "{coordinator} 위한 세션 노트 추가",
      backLinkText: "이전으로 돌아가기",
      pageHeader: "@:screens.SessionNotesAdd.pageTitle",
      modalConfirmationHeader: "세션 메모 추가됨",
      modalConfirmationAnotherLink: "다른 세션 메모 추가",
      topicsLabel: "논의한 카테고리/주제 선택",
      notesCharacterCount: "{current}자 중 {max}자 남음",
      meetingTypeLabel: "회의 유형",
      meetingTypes: {
        phoneCall: "전화",
        inPerson: "대면 회의",
        chat: "채팅 메시징 세션",
        videoAudio: "영상/음성통화"
      },
      urgentWarning: "이것이 즉각적인 고려가 필요한 긴급 문제인 경우 코디네이터에게 문의하세요. 응급 상황인 경우 911에 전화하거나 가장 가까운 응급실로 가도록 요청하세요."
    },
    PeerMentorMessaging: {
      pageTitle: "채팅",
      menteeCall: "의료 질문에 대한 콜 케어 팀",
      mentorCall: "의료 질문? {peer}에게 케어팀에 전화해달라고 요청하세요.",
      tabChat: "@:screens.PeerMentorMessaging.pageTitle",
      tabDetails: "{userType}세부정보",
      tabSessionNotes: "세션 메모",
      addSessionNote: "새 세션 메모 추가",
      conversationStarter: "대화 스타터의 예:",
      copyText: "이 텍스트를 내 메시지에 복사",
      dismiss: "해고하다",
      notEnrolled: "이 사람은 아직 등록되지 않았습니다. 전화하여 참여를 시작하고 등록하도록 유도하십시오. 감사합니다!",
      videoButtonDefaultText: "화상 통화 시작"
    },
    MentorSessionNotesDetail: {
      pageTitle: "세션 메모",
      backLinkText: "세션 노트로 돌아가기",
      duration: "{duration}분",
      topicsLabelView: "회의 주제",
    },
    Logout: {
      pageTitle: "로그아웃됨",
      pageHeader: "성공적으로 로그아웃했습니다."
    },
    ForgotPassword: {
      pageTitle: "암호 잊음",
      backLinkText: "로그인 화면으로 돌아가기",
      pageHeader: "암호를 잊으셨나요?"
    },
    ForgotPasswordConfirmation: {
      pageTitle: "@:screens.ForgotPassword.pageTitle",
      backLinkText: "@:screens.ForgotPassword.backLinkText",
      pageHeader: "성공",
      textSentPassword: "귀하의 이메일 주소로 암호 재설정 링크를 보냈습니다.",
      textClickLink:
        "이메일에 있는 링크를 클릭하여 새 암호를 만든 다음 새 암호를 사용하여 로그인하십시오."
    },
    ForgotPasswordReset: {
      pageTitle: "@:screens.ForgotPasswordReset.pageHeader",
      pageHeader: "암호 재설정",
      errorMessage: "암호 재설정 토큰이 인식되지 않았거나 만료되었습니다.",
      errorMessageHaveUsed: "이전에 이 암호를 사용한 적이 있습니다."
    },
    NotFound: {
      pageTitle: "@:screens.NotFound.pageHeader",
      pageHeader: "페이지를 찾을 수 없음",
      header: "페이지를 찾을 수 없음",
      message: "존재하지 않는 페이지에 액세스를 시도한 것 같습니다."
    },
    Survey: {
      welcome: "환영합니다",
      ratingAriaDescription: "1에서{num}까지의 척도",
      modalConfirmationHeader: "감사합니다",
      modalConfirmationText: "귀하의 설문조사 응답을 받았습니다."
    },
    Welcome: {
      welcome: "@:screens.Survey.welcome",
      ratingAriaDescription: "1에서 {num}까지의 척도",
      thankYou: "사회 환원에 감사드립니다!",
      makeDifference: "귀하는 사람들의 삶을 크게 변화시키고 있습니다.",
      peopleMentored: "멘토링을 받는 사람 | 멘토링을 받는 사람 | 멘토링을 받는 사람",
      serviceHours:
        "총 서비스 시간 | 총 서비스 시간 | 총 서비스 시간"
    }
  },
  components: {
    Messaging: {
      firstMessagePrompt: "새로 들어온{userType}에게 인사하세요!",
      errorEmptyMessage:
        "하나 이상의 단어를 입력하고 Enter 키를 눌러 메시지를 보내십시오.",
      surveyButton: "질문 1개에 답하기"
    },
    UserDetails: {
      notesHeader: "개인 메모",
      doNotText: "동료에게 문자 메시지를 보내지 마십시오. 모든 인스턴트 메시징{app} 채팅을 통해 수행해야 합니다"
    }
  }
};
export default ko;
