import InTandemApi from "./InTandemApi";

export default class UserPermissions {
  // These defaults should be overwritten with values passed to the constructor by App.vue
  COORD_USER_TYPE = "coordinator";
  MENTOR_USER_TYPE = "mentor";
  MENTEE_USER_TYPE = "mentee";
  UNAUTHENTICATED_USER_TYPE = "notAuthenticated";

  PERSISTED_STORAGE_KEY = "ith";

  // current user permissions
  token = null; // The JWT  returned by the login API function
  userType = this.UNAUTHENTICATED_USER_TYPE; // admin | mentor | peer | notAuthenticated (default)
  hasConsented = false; // Boolean; true if the user has agreed to the T&C
  pwExpires = null; // Days until PW expiration; negative is already expired.
  loginSurvey = null; // Survey to show after login (if any)
  logoutSurvey = null; // Survey to show after logout (if any)
  mentorStats = 0; // Stats to display to mentors after login
  showShareStory = false; // Toggle display of share story on interstitial
  shareStoryUrl = ""; // URL for share story button

  constructor(config) {
    // apply configuration
    this.COORD_USER_TYPE = config.COORD_USER_TYPE;
    this.MENTOR_USER_TYPE = config.MENTOR_USER_TYPE;
    this.MENTEE_USER_TYPE = config.MENTEE_USER_TYPE;

    // Load the persistedSession, if any
    this.loadPersistedPermissions();

    // We need to use the API for the authenticate() method
    this.api = new InTandemApi();
  }

  // Authenticates the user through the API
  authenticate(email, password, callback) {
    this.api.login(email, password, (response) => {
      // Response will be an object with user permissions on success, false on bad user/pw
      // We return true if authentication succeeded, else false
      console.log(response);
      if (response) {
        if (response.error) {
          console.log("authenticate error");
          callback(response);
          return;
        }
        this.setupUser(response);
        callback(response);
      } else {
        callback(false);
      }
    });
  }

  setupUser(apiResponse) {
    const loadResponse = this.loadPermissions(apiResponse);
    if (loadResponse) {
      this.persistPermissions();
    }
  }

  // Parses the JSON output from the web service call and sets user's permissions
  loadPermissions(apiResponse) {
    console.log(apiResponse);
    try {
      this.token = apiResponse.token;
      if (apiResponse.user_type === "coordinator") {
        this.userType = this.COORD_USER_TYPE;
      } else if (apiResponse.user_type === "mentee") {
        this.userType = this.MENTEE_USER_TYPE;
      } else {
        this.userType = this.MENTOR_USER_TYPE;
      }
      this.hasConsented = apiResponse.consent_signed;
      this.pwExpires = apiResponse.days_for_expiration;
      this.loginSurvey = apiResponse.survey;
      this.showShareStory = apiResponse.share_story.show_button;
      this.shareStoryUrl = apiResponse.share_story.url;
      // mentor stats will only be sent when the user is a mentor
      if ("total_peers" in apiResponse) {
        this.mentorStats = {
          peers: apiResponse.total_peers,
          hours: apiResponse.total_session_hours,
          mentors: apiResponse.total_mentors,
        };
      }
      console.log("User authenticated. User type: " + this.userType);
      return true;
    } catch {
      return false;
    }
  }

  persistPermissions() {
    // using cryptic keys here for a little security by obscurity
    let userTypeMap = new Map([
      [this.COORD_USER_TYPE, "d"],
      [this.MENTEE_USER_TYPE, "s"],
      [this.MENTOR_USER_TYPE, "w"],
    ]);
    let permissions = {
      a: this.token,
      mrp: this.pwExpires,
      hmp: userTypeMap.get(this.userType),
      mlh: this.hasConsented,
      s1: this.loginSurvey,
      ms: this.mentorStats,
      sf: this.showShareStory,
      su: this.shareStoryUrl
    };
    // TODO: test storage, try cookie back on failure
    // Test code: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
    this.saveToSessionStorage(permissions);
  }

  // TODO: check that saved item was set correctly
  saveToSessionStorage(permissions) {
    try {
      sessionStorage.setItem(
        this.PERSISTED_STORAGE_KEY,
        JSON.stringify(permissions)
      );
    } catch (err) {
      console.log(err);
      return false;
    }
    return true;
  }

  // Grabs a persisted session if there is one. Should only be called by the constructor
  // TODO: try cookies if not in sessionStorage
  loadPersistedPermissions() {
    let revMap = new Map([
      ["d", this.COORD_USER_TYPE],
      ["s", this.MENTEE_USER_TYPE],
      ["w", this.MENTOR_USER_TYPE],
    ]);
    let persistedJson = sessionStorage.getItem(this.PERSISTED_STORAGE_KEY);
    if (persistedJson) {
      let permissions = JSON.parse(persistedJson);
      this.token = permissions.a;
      this.pwExpires = permissions.mrp;
      this.userType = revMap.get(permissions.hmp);
      this.hasConsented = permissions.mlh;
      this.loginSurvey = permissions.s1;
      this.mentorStats = permissions.ms;
      this.showShareStory = permissions.sf;
      this.shareStoryUrl = permissions.su;
      return true;
    }
    return false;
  }

  // TODO: add API call
  logout() {
    this.deletePersistedPermissions();
    this.token = null;
    this.userType = this.UNAUTHENTICATED_USER_TYPE;
    this.hasConsented = false;
    this.pwExpires = null;
    this.mentorStats = 0;
  }

  // TODO: check if was deleted; also delete from cookies
  deletePersistedPermissions() {
    sessionStorage.removeItem(this.PERSISTED_STORAGE_KEY); // Returns nothing, whether removal succeeded or failed
  }

  // Accepts an object with the permissions values you would like to update
  updatePermissions(permissions) {
    if (typeof permissions === "object") {
      for (const key in permissions) {
        this[key] = permissions[key];
      }
      this.persistPermissions();
    } else {
      return false;
    }
  }

  // Returns true if a password expiration message should be shown to the user
  isPasswordExpiring() {
    if (this.pwExpires <= 5) {
      return true;
    }
    return false;
  }

  isAuthenticated() {
    return this.token !== null;
  }

  getUserType() {
    return this.userType;
  }

  userIsMentee() {
    return this.userType === this.MENTEE_USER_TYPE;
  }

  userIsMentor() {
    return this.userType === this.MENTOR_USER_TYPE;
  }

  userIsCoordinator() {
    return this.userType === this.COORD_USER_TYPE;
  }

  incrementStat(type, number=1) {
    if (typeof this.mentorStats[type] == "number") {
      const newTotal = this.mentorStats[type] + number;
      if (newTotal > 0) {
        this.mentorStats[type] = newTotal;
      }
    } 
  }

  decrementStat(type, number=1) {
    if (typeof this.mentorStats[type] == "number") {
      const newTotal = this.mentorStats[type] - number;
      if (newTotal > 0) {
        this.mentorStats[type] = newTotal;
      }
    }
  }

  // If user needs to do something before viewing a route, returns an array of actions
  // Otherwise returns null
  requiresActions() {
    let output = new Set();
    if (this.pwExpires < 0) {
      output.add("changePassword");
    }
    if (!this.hasConsented) {
      output.add("consentToTerms");
    }
    if (output.size > 0) {
      return output;
    }
    return null;
  }

  // Superseded by isAuthenticated() and requiresActions(), but keeping for debugging
  getPermissions() {
    let output = {};

    output.isAuthenticated = this.token !== null;
    // the router needs to pass the userType to components that have variable display based on userType
    output.userType = this.userType;

    // Indicate anything the router should warn the user about
    // Change this to the appropriate string?
    // Need to rework date compare logic
    output.warn = [];
    if (
      this.pwExpiration !== null &&
      this.pwExpires >= 0 &&
      this.pwExpires <= 5
    ) {
      output.warn.push("password");
    }

    // Indicate any tasks the router should force the user to do before proceeding
    output.force = [];
    if (this.pwExpiration === null || this.pwExpires < 0) {
      output.force.push("password");
    }
    if (!this.hasConsented) {
      output.force.push("consent");
    }

    return output;
  }
}
